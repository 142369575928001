function Faq() {
	return (
		<div>
			<div className="sub-header">
				<div className="container">
					<h1>Faq</h1>
				</div>
			</div>

			<main>
				<div className="faq">
					<div className="container">
						<div className="row">
							<div className="col-lg-12">
								<div className="main-title">
									<span>
										<em></em>
									</span>
									<h2>Introduction</h2>
								</div>
								<div role="tablist" className="faq-accordion" id="topic1">
									<div className="card">
										<div className="card-header" role="tab">
											<h4 className="mb-0">
												<a
													data-toggle="collapse"
													href="#subTopic1"
													aria-expanded="true"
												>
													<i className="indicator icon icon-minus"></i>Who we are?
												</a>
											</h4>
										</div>
										<div
											id="subTopic1"
											className="collapse show"
											role="tabpanel"
											data-parent="#topic1"
										>
											<div className="card-body">
												<p>
													Facere possimus, omnis voluptas assumenda est, omnis
													dolor repellendus. Facere possimus, omnis voluptas
													assumenda est, omnis dolor repellendus. Cupiditate non
													provident, similique sunt in culpa qui officia
													deserunt mollitia. Quia consequuntur magni dolores eos
													qui ratione voluptatem sequi nesciunt.
												</p>
												<p>
													Facere possimus, omnis voluptas assumenda est, omnis
													dolor repellendus. Facere possimus, omnis voluptas
													assumenda est, omnis dolor repellendus. Cupiditate non
													provident, similique sunt in culpa qui officia
													deserunt mollitia. Quia consequuntur magni dolores eos
													qui ratione voluptatem sequi nesciunt.
												</p>
											</div>
										</div>
									</div>

									<div className="card">
										<div className="card-header" role="tab">
											<h4 className="mb-0">
												<a
													className="collapsed"
													data-toggle="collapse"
													href="#subTopic2"
													aria-expanded="false"
												>
													<i className="indicator icon icon-plus"></i>What we do?
												</a>
											</h4>
										</div>
										<div
											id="subTopic2"
											className="collapse"
											role="tabpanel"
											data-parent="#topic1"
										>
											<div className="card-body">
												<p>
													Facere possimus, omnis voluptas assumenda est, omnis
													dolor repellendus. Facere possimus, omnis voluptas
													assumenda est, omnis dolor repellendus. Cupiditate non
													provident, similique sunt in culpa qui officia
													deserunt mollitia. Quia consequuntur magni dolores eos
													qui ratione voluptatem sequi nesciunt.
												</p>
												<p>
													Facere possimus, omnis voluptas assumenda est, omnis
													dolor repellendus. Facere possimus, omnis voluptas
													assumenda est, omnis dolor repellendus. Cupiditate non
													provident, similique sunt in culpa qui officia
													deserunt mollitia. Quia consequuntur magni dolores eos
													qui ratione voluptatem sequi nesciunt.
												</p>
											</div>
										</div>
									</div>

									<div className="card">
										<div className="card-header" role="tab">
											<h4 className="mb-0">
												<a
													className="collapsed"
													data-toggle="collapse"
													href="#subTopic3"
													aria-expanded="false"
												>
													<i className="indicator icon icon-plus"></i>Where are we
													located?
												</a>
											</h4>
										</div>
										<div
											id="subTopic3"
											className="collapse"
											role="tabpanel"
											data-parent="#topic1"
										>
											<div className="card-body">
												<p>
													Facere possimus, omnis voluptas assumenda est, omnis
													dolor repellendus. Facere possimus, omnis voluptas
													assumenda est, omnis dolor repellendus. Cupiditate non
													provident, similique sunt in culpa qui officia
													deserunt mollitia. Quia consequuntur magni dolores eos
													qui ratione voluptatem sequi nesciunt.
												</p>
												<p>
													Facere possimus, omnis voluptas assumenda est, omnis
													dolor repellendus. Facere possimus, omnis voluptas
													assumenda est, omnis dolor repellendus. Cupiditate non
													provident, similique sunt in culpa qui officia
													deserunt mollitia. Quia consequuntur magni dolores eos
													qui ratione voluptatem sequi nesciunt.
												</p>
											</div>
										</div>
									</div>
								</div>

								<div className="main-title">
									<span>
										<em></em>
									</span>
									<h2>Processing order</h2>
								</div>
								<div role="tablist" className="faq-accordion" id="topic2">
									<div className="card">
										<div className="card-header" role="tab">
											<h4 className="mb-0">
												<a
													data-toggle="collapse"
													href="#subTopic4"
													aria-expanded="true"
												>
													<i className="indicator icon icon-plus"></i>How can I
													process an order?
												</a>
											</h4>
										</div>
										<div
											id="subTopic4"
											className="collapse"
											role="tabpanel"
											data-parent="#topic2"
										>
											<div className="card-body">
												<p>
													Facere possimus, omnis voluptas assumenda est, omnis
													dolor repellendus. Facere possimus, omnis voluptas
													assumenda est, omnis dolor repellendus. Cupiditate non
													provident, similique sunt in culpa qui officia
													deserunt mollitia. Quia consequuntur magni dolores eos
													qui ratione voluptatem sequi nesciunt.
												</p>
												<p>
													Facere possimus, omnis voluptas assumenda est, omnis
													dolor repellendus. Facere possimus, omnis voluptas
													assumenda est, omnis dolor repellendus. Cupiditate non
													provident, similique sunt in culpa qui officia
													deserunt mollitia. Quia consequuntur magni dolores eos
													qui ratione voluptatem sequi nesciunt.
												</p>
											</div>
										</div>
									</div>

									<div className="card">
										<div className="card-header" role="tab">
											<h4 className="mb-0">
												<a
													className="collapsed"
													data-toggle="collapse"
													href="#subTopic5"
													aria-expanded="false"
												>
													<i className="indicator icon icon-plus"></i>Will I get
													order confirmation?
												</a>
											</h4>
										</div>
										<div
											id="subTopic5"
											className="collapse"
											role="tabpanel"
											data-parent="#topic2"
										>
											<div className="card-body">
												<p>
													Facere possimus, omnis voluptas assumenda est, omnis
													dolor repellendus. Facere possimus, omnis voluptas
													assumenda est, omnis dolor repellendus. Cupiditate non
													provident, similique sunt in culpa qui officia
													deserunt mollitia. Quia consequuntur magni dolores eos
													qui ratione voluptatem sequi nesciunt.
												</p>
												<p>
													Facere possimus, omnis voluptas assumenda est, omnis
													dolor repellendus. Facere possimus, omnis voluptas
													assumenda est, omnis dolor repellendus. Cupiditate non
													provident, similique sunt in culpa qui officia
													deserunt mollitia. Quia consequuntur magni dolores eos
													qui ratione voluptatem sequi nesciunt.
												</p>
											</div>
										</div>
									</div>

									<div className="card">
										<div className="card-header" role="tab">
											<h4 className="mb-0">
												<a
													className="collapsed"
													data-toggle="collapse"
													href="#subTopic6"
													aria-expanded="false"
												>
													<i className="indicator icon icon-plus"></i>In which time
													can I order?
												</a>
											</h4>
										</div>
										<div
											id="subTopic6"
											className="collapse"
											role="tabpanel"
											data-parent="#topic2"
										>
											<div className="card-body">
												<p>
													Facere possimus, omnis voluptas assumenda est, omnis
													dolor repellendus. Facere possimus, omnis voluptas
													assumenda est, omnis dolor repellendus. Cupiditate non
													provident, similique sunt in culpa qui officia
													deserunt mollitia. Quia consequuntur magni dolores eos
													qui ratione voluptatem sequi nesciunt.
												</p>
												<p>
													Facere possimus, omnis voluptas assumenda est, omnis
													dolor repellendus. Facere possimus, omnis voluptas
													assumenda est, omnis dolor repellendus. Cupiditate non
													provident, similique sunt in culpa qui officia
													deserunt mollitia. Quia consequuntur magni dolores eos
													qui ratione voluptatem sequi nesciunt.
												</p>
											</div>
										</div>
									</div>
								</div>

								<div className="main-title">
									<span>
										<em></em>
									</span>
									<h2>Payment</h2>
								</div>
								<div role="tablist" className="faq-accordion" id="topic3">
									<div className="card">
										<div className="card-header" role="tab">
											<h4 className="mb-0">
												<a
													data-toggle="collapse"
													href="#subTopic7"
													aria-expanded="true"
												>
													<i className="indicator icon icon-plus"></i>How can I pay
													for the order?
												</a>
											</h4>
										</div>
										<div
											id="subTopic7"
											className="collapse"
											role="tabpanel"
											data-parent="#topic3"
										>
											<div className="card-body">
												<p>
													Facere possimus, omnis voluptas assumenda est, omnis
													dolor repellendus. Facere possimus, omnis voluptas
													assumenda est, omnis dolor repellendus. Cupiditate non
													provident, similique sunt in culpa qui officia
													deserunt mollitia. Quia consequuntur magni dolores eos
													qui ratione voluptatem sequi nesciunt.
												</p>
												<p>
													Facere possimus, omnis voluptas assumenda est, omnis
													dolor repellendus. Facere possimus, omnis voluptas
													assumenda est, omnis dolor repellendus. Cupiditate non
													provident, similique sunt in culpa qui officia
													deserunt mollitia. Quia consequuntur magni dolores eos
													qui ratione voluptatem sequi nesciunt.
												</p>
											</div>
										</div>
									</div>

									<div className="card">
										<div className="card-header" role="tab">
											<h4 className="mb-0">
												<a
													className="collapsed"
													data-toggle="collapse"
													href="#subTopic8"
													aria-expanded="false"
												>
													<i className="indicator icon icon-plus"></i>Which card is
													accepted at online payment?
												</a>
											</h4>
										</div>
										<div
											id="subTopic8"
											className="collapse"
											role="tabpanel"
											data-parent="#topic3"
										>
											<div className="card-body">
												<p>
													Facere possimus, omnis voluptas assumenda est, omnis
													dolor repellendus. Facere possimus, omnis voluptas
													assumenda est, omnis dolor repellendus. Cupiditate non
													provident, similique sunt in culpa qui officia
													deserunt mollitia. Quia consequuntur magni dolores eos
													qui ratione voluptatem sequi nesciunt.
												</p>
												<p>
													Facere possimus, omnis voluptas assumenda est, omnis
													dolor repellendus. Facere possimus, omnis voluptas
													assumenda est, omnis dolor repellendus. Cupiditate non
													provident, similique sunt in culpa qui officia
													deserunt mollitia. Quia consequuntur magni dolores eos
													qui ratione voluptatem sequi nesciunt.
												</p>
											</div>
										</div>
									</div>

									<div className="card">
										<div className="card-header" role="tab">
											<h4 className="mb-0">
												<a
													className="collapsed"
													data-toggle="collapse"
													href="#subTopic9"
													aria-expanded="false"
												>
													<i className="indicator icon icon-plus"></i>Does courier
													accept card for the payment?
												</a>
											</h4>
										</div>
										<div
											id="subTopic9"
											className="collapse"
											role="tabpanel"
											data-parent="#topic3"
										>
											<div className="card-body">
												<p>
													Facere possimus, omnis voluptas assumenda est, omnis
													dolor repellendus. Facere possimus, omnis voluptas
													assumenda est, omnis dolor repellendus. Cupiditate non
													provident, similique sunt in culpa qui officia
													deserunt mollitia. Quia consequuntur magni dolores eos
													qui ratione voluptatem sequi nesciunt.
												</p>
												<p>
													Facere possimus, omnis voluptas assumenda est, omnis
													dolor repellendus. Facere possimus, omnis voluptas
													assumenda est, omnis dolor repellendus. Cupiditate non
													provident, similique sunt in culpa qui officia
													deserunt mollitia. Quia consequuntur magni dolores eos
													qui ratione voluptatem sequi nesciunt.
												</p>
											</div>
										</div>
									</div>
								</div>

								<div className="main-title">
									<span>
										<em></em>
									</span>
									<h2>Delivery</h2>
								</div>
								<div role="tablist" className="faq-accordion" id="topic4">
									<div className="card">
										<div className="card-header" role="tab">
											<h4 className="mb-0">
												<a
													data-toggle="collapse"
													href="#subTopic10"
													aria-expanded="true"
												>
													<i className="indicator icon icon-plus"></i>How much time
													does it take to get my order?
												</a>
											</h4>
										</div>
										<div
											id="subTopic10"
											className="collapse"
											role="tabpanel"
											data-parent="#topic4"
										>
											<div className="card-body">
												<p>
													Facere possimus, omnis voluptas assumenda est, omnis
													dolor repellendus. Facere possimus, omnis voluptas
													assumenda est, omnis dolor repellendus. Cupiditate non
													provident, similique sunt in culpa qui officia
													deserunt mollitia. Quia consequuntur magni dolores eos
													qui ratione voluptatem sequi nesciunt.
												</p>
												<p>
													Facere possimus, omnis voluptas assumenda est, omnis
													dolor repellendus. Facere possimus, omnis voluptas
													assumenda est, omnis dolor repellendus. Cupiditate non
													provident, similique sunt in culpa qui officia
													deserunt mollitia. Quia consequuntur magni dolores eos
													qui ratione voluptatem sequi nesciunt.
												</p>
											</div>
										</div>
									</div>

									<div className="card">
										<div className="card-header" role="tab">
											<h4 className="mb-0">
												<a
													className="collapsed"
													data-toggle="collapse"
													href="#subTopic11"
													aria-expanded="false"
												>
													<i className="indicator icon icon-plus"></i>In which area
													do you provide delivery?{" "}
												</a>
											</h4>
										</div>
										<div
											id="subTopic11"
											className="collapse"
											role="tabpanel"
											data-parent="#topic4"
										>
											<div className="card-body">
												<p>
													Facere possimus, omnis voluptas assumenda est, omnis
													dolor repellendus. Facere possimus, omnis voluptas
													assumenda est, omnis dolor repellendus. Cupiditate non
													provident, similique sunt in culpa qui officia
													deserunt mollitia. Quia consequuntur magni dolores eos
													qui ratione voluptatem sequi nesciunt.
												</p>
												<p>
													Facere possimus, omnis voluptas assumenda est, omnis
													dolor repellendus. Facere possimus, omnis voluptas
													assumenda est, omnis dolor repellendus. Cupiditate non
													provident, similique sunt in culpa qui officia
													deserunt mollitia. Quia consequuntur magni dolores eos
													qui ratione voluptatem sequi nesciunt.
												</p>
											</div>
										</div>
									</div>

									<div className="card mb-0">
										<div className="card-header" role="tab">
											<h4 className="mb-0">
												<a
													className="collapsed"
													data-toggle="collapse"
													href="#subTopic12"
													aria-expanded="false"
												>
													<i className="indicator icon icon-plus"></i>Do you have
													delivery fee?
												</a>
											</h4>
										</div>
										<div
											id="subTopic12"
											className="collapse"
											role="tabpanel"
											data-parent="#topic4"
										>
											<div className="card-body">
												<p>
													Facere possimus, omnis voluptas assumenda est, omnis
													dolor repellendus. Facere possimus, omnis voluptas
													assumenda est, omnis dolor repellendus. Cupiditate non
													provident, similique sunt in culpa qui officia
													deserunt mollitia. Quia consequuntur magni dolores eos
													qui ratione voluptatem sequi nesciunt.
												</p>
												<p>
													Facere possimus, omnis voluptas assumenda est, omnis
													dolor repellendus. Facere possimus, omnis voluptas
													assumenda est, omnis dolor repellendus. Cupiditate non
													provident, similique sunt in culpa qui officia
													deserunt mollitia. Quia consequuntur magni dolores eos
													qui ratione voluptatem sequi nesciunt.
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</main>

			<footer className="main-footer">
				<div className="container">
					<div className="row">
						<div className="col-md-3">
							<h5 className="footer-heading">Menu Links</h5>
							<ul className="list-unstyled nav-links">
								<li>
									<i className="fa fa-angle-right"></i>{" "}
									<a
										href="https://ultimatewebsolutions.net/foodboard/"
										className="footer-link"
									>
										Home
									</a>
								</li>
								<li>
									<i className="fa fa-angle-right"></i>{" "}
									<a href="faq.html" className="footer-link">
										FAQ
									</a>
								</li>
								<li>
									<i className="fa fa-angle-right"></i>{" "}
									<a href="contacts.html" className="footer-link">
										Contacts
									</a>
								</li>
							</ul>
						</div>
						<div className="col-md-3">
							<h5 className="footer-heading">Order Wizard</h5>
							<ul className="list-unstyled nav-links">
								<li>
									<i className="fa fa-angle-right"></i>{" "}
									<a href="pay-with-card-online/" className="footer-link">
										Pay online
									</a>
								</li>
								<li>
									<i className="fa fa-angle-right"></i>{" "}
									<a href="pay-with-cash-on-delivery/" className="footer-link">
										Pay with cash on delivery
									</a>
								</li>
							</ul>
						</div>
						<div className="col-md-4">
							<h5 className="footer-heading">Contacts</h5>
							<ul className="list-unstyled contact-links">
								<li>
									<i className="icon icon-map-marker"></i>
									<a
										href="https://goo.gl/maps/vKgGyZe2JSRLDnYH6"
										className="footer-link"
										target="_blank"
									>
										Address: 1234 Street Name, City Name, USA
									</a>
								</li>
								<li>
									<i className="icon icon-envelope3"></i>
									<a href="mailto:info@yourdomain.com" className="footer-link">
										Mail: info@yourdomain.com
									</a>
								</li>
								<li>
									<i className="icon icon-phone2"></i>
									<a href="tel:+3630123456789" className="footer-link">
										Phone: +3630123456789
									</a>
								</li>
							</ul>
						</div>
						<div className="col-md-2">
							<h5 className="footer-heading">Find Us On</h5>
							<ul className="list-unstyled social-links">
								<li>
									<a
										href="https://facebook.com"
										className="social-link"
										target="_blank"
									>
										<i className="icon icon-facebook"></i>
									</a>
								</li>
								<li>
									<a
										href="https://twitter.com"
										className="social-link"
										target="_blank"
									>
										<i className="icon icon-twitter"></i>
									</a>
								</li>
								<li>
									<a
										href="https://instagram.com"
										className="social-link"
										target="_blank"
									>
										<i className="icon icon-instagram"></i>
									</a>
								</li>
								<li>
									<a
										href="https://pinterest.com"
										className="social-link"
										target="_blank"
									>
										<i className="icon icon-pinterest"></i>
									</a>
								</li>
							</ul>
						</div>
					</div>

					<div className="row">
						<div className="col-md-8">
							<ul id="subFooterLinks">
								<li>
									<a
										href="https://themeforest.net/user/ultimatewebsolutions"
										target="_blank"
									>
										With <i className="fa fa-heart pulse"></i> by UWS
									</a>
								</li>
								<li>
									<a href="pdf/terms.pdf" target="_blank">
										Terms and conditions
									</a>
								</li>
							</ul>
						</div>
						<div className="col-md-4">
							<div id="copy">© 2021 FoodBoard</div>
						</div>
					</div>
				</div>
			</footer>
		</div>
	);
}

export default Faq;
