import { useState, useEffect, useContext } from "react";
import {
	createUserWithEmailAndPassword,
	signOut,
	sendPasswordResetEmail,
} from "firebase/auth";
import { main } from "../logic/firebaseLogic/firebaseConfig";
import { functions } from "../logic/firebaseLogic/firebaseConfig";
import { AuthContext, Loading, ModalContext } from "../context/context";
import { httpsCallable } from "firebase/functions";
import { useNavigate } from "react-router-dom";
import { db } from "../logic/firebaseLogic/firebaseConfig";
import { addDoc, collection, getDocs } from "firebase/firestore";
export const Profile = () => {
	const { auth, isUser } = useContext(AuthContext);
	const { setLoading } = useContext(Loading);
	const { setError, setResponse } = useContext(ModalContext);
	const [unsubscribed, setUnsubscribed] = useState(false);
	const navigate = useNavigate();
	useEffect(() => {
		if (!isUser) {
			navigate("/");
		} else {
			getEmailSubscription();
		}
	}, [isUser]);
	async function getEmailSubscription() {
		const shippingCollectionRef = collection(
			db,
			"doNotEmail",
			isUser.uid,
			"email"
		);
		const snapshot = await getDocs(shippingCollectionRef);
		if (snapshot.empty) {
			return;
		} else {
			setUnsubscribed(true);
		}
	}
	async function goToBillingPortal() {
		setLoading(true);
		try {
			const functionRef = httpsCallable(
				functions,
				"ext-firestore-stripe-payments-createPortalLink"
			);

			const { data } = await functionRef({ returnUrl: window.location.origin });
			window.location.assign(data.url);
		} catch (error) {
			setError(error);
		} finally {
			setLoading(false);
		}
	}

	const handleManageShippingInformation = () => {
		navigate("/shipping");
	};

	const handleUnsubscribeEmail = async () => {
		setLoading(true);
		try {
			const currentUser = isUser.uid;

			await addDoc(collection(db, "doNotEmail", currentUser, "email"), {
				email: isUser.email,
			});
			setResponse("Successfully usubscribed");
			getEmailSubscription();
		} catch (error) {
			setError(error);
		} finally {
			setLoading(false);
		}
	};

	return (
		<div className="container my-4">
			<div className="row justify-content-center">
				<div className="col-md-10">
					<div className="card">
						<div className="card-body">
							<h5 className="card-title">Profile</h5>
							<p className="card-text mb-3">Email: {isUser.email}</p>
							<div className="row justify-content-between">
								<div className="col-12">
									<button
										className="btn btn-primary w-100"
										onClick={goToBillingPortal}
									>
										Manage Subscriptions
									</button>
								</div>
								<div className="col-12 mt-5">
									<button
										className="btn btn-primary w-100"
										onClick={handleManageShippingInformation}
									>
										Manage Shipping Information
									</button>
								</div>
							</div>

							<div>
								<button
									className="btn btn-primary ml-3 mt-5 w-35"
									onClick={() => signOut(auth)}
								>
									Log out
								</button>
							</div>
							<div
								className="d-flex justify-content-between"
								style={{ marginTop: "10em" }}
							>
								{!unsubscribed && (
									<button
										className="btn btn-danger ml-3"
										onClick={handleUnsubscribeEmail}
									>
										Unsubscribe from Email List
									</button>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Profile;
