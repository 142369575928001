import { Link } from "react-router-dom";

const Footer = () => {
	return (
		<footer className="main-footer">
			<div className="container">
				<div className="row justify-content-between">
					<div className="col">
						<h5 className="footer-heading">Menu Links</h5>
						<ul className="list-unstyled nav-links">
							<li>
								<i className="fa fa-angle-right"></i>
								<Link to="/" className="footer-link">
									Home
								</Link>
							</li>
							<li>
								<i className="fa fa-angle-right"></i>
								<Link to="/faq" className="footer-link">
									FAQ
								</Link>
							</li>
							<li>
								<i className="fa fa-angle-right"></i>
								<Link to="/contacts" className="footer-link">
									Contacts
								</Link>
							</li>
						</ul>
					</div>
					<div className="col text-center">
						<h5 className="footer-heading">Contacts</h5>
						<ul className="list-unstyled contact-links">
							<li>
								<i className="icon icon-envelope3"></i>
								<Link to="mailto:help@upliftcrate.com" className="footer-link">
									Mail: help@upliftcrate.com
								</Link>
							</li>
							<li>
								<i className="icon icon-phone2"></i>
								<Link to="tel:+19047539267" className="footer-link">
									Phone: +1 904 753 9267
								</Link>
							</li>
						</ul>
					</div>
				</div>
				<div className="row justify-content-between">
					<div className="col">
						<ul id="subFooterLinks">
							<li>
								<Link to="/pdf/terms.pdf" target="_blank">
									Terms and conditions
								</Link>
							</li>
						</ul>
					</div>
					<div className="col text-right">
						<div id="copy">© 2023 Uplift Crate</div>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
