import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { SubscriptionSelectedContext, AuthContext } from "../context/context";
import { ShippingForm } from "./ShippingForm";
export const AuthenticateCheckout = () => {
	const [subscription, setSubscription] = useContext(
		SubscriptionSelectedContext
	);
	const { showAuth, setShowAuth, isUser } = useContext(AuthContext);
	const navigate = useNavigate();

	if (isUser) {
		return (
			<ShippingForm checkout={true}/>
		)
	} else {
		return (
			<div className="card p-3 toProceed">
				<h2 className="card-title">One More Step</h2>
				<p className="card-subtitle mb-2">
					To complete your checkout, we need you to authenticate your account.
					This will allow you to:
				</p>
				<ul className="list-group list-group-flush mb-3">
					<li className="d-flex align-items-center pb-2 list-group-item">
						<i aria-hidden="true" className="icon icon-chevron-right  pr-2"></i>
						<span>Manage your subscriptions.</span>
					</li>
					<li className="d-flex align-items-center pb-2 list-group-item">
						<i aria-hidden="true" className="icon icon-chevron-right  pr-2"></i>
						<span>Track your shipment.</span>
					</li>
					<li className="d-flex align-items-center pb-2 list-group-item">
						<i aria-hidden="true" className="icon icon-chevron-right  pr-2"></i>
						<span>Get the latest updates.</span>
					</li>
				</ul>
				<button className="btn cta-button" onClick={() => setShowAuth(true)}>
					Authenticate
				</button>
				<button className="btn btn-secondary" onClick={() => navigate("/")}>
					Back To Plan Selection
				</button>
			</div>
		);
	}
};
