import envelopeImage from "../img/bg/lazy-placeholder.jpg";
import { Carousel } from "react-responsive-carousel";

const IconBox = ({ icon, title }) => (
	<div className="icon-box d-flex align-items-center">
		<div className="icon-box-icon">
			<span className="icon">
				<i aria-hidden="true" className={`${icon} pr-2`}></i>
			</span>
		</div>
		<div className="icon-box-content">
			<span>{title}</span>
		</div>
	</div>
);

export const WhatsInside = ({ setSubscriptionStep }) => {
	const iconBoxes = [
		{ icon: "icon icon-tag", title: "Free Shipping" },
		{ icon: "icon icon-thumbs-up", title: "Satisfaction Guaranteed" },
		{ icon: "icon icon-undo", title: "Cancel Anytime" },
	];

	return (
		<div className="col-lg-12 animated-element whatsInside">
			<div className="boxMainWithImage d-flex">
				<div className="box-image">
					<Carousel className="carousel" showStatus={false}>
						<div>
							<img src={envelopeImage} />
						</div>
						<div>
							<img src={envelopeImage} />
						</div>
						<div>
							<img src={envelopeImage} />
						</div>
					</Carousel>
				</div>
				<div className="box-content">
					<h3 className="service-title">What's inside the box?</h3>
					<p className="planInfo">
						Begin your journey of positivity and inspiration. With our Envelope
						Edition, every month brings new tales from across the globe —
						stories that uplift, inspire, and introduce you to the many good
						things happening in our world.
					</p>
					<div className="container">
						<ul className="list-unstyled whatsInsideList">
							<li className="d-flex align-items-center pb-3">
								<i
									aria-hidden="true"
									className="icon icon-chevron-right  pr-2 "
								></i>
								<span>Handpicked stories of remarkable kindness.</span>
							</li>
							<li className="d-flex align-items-center pb-3">
								<i
									aria-hidden="true"
									className="icon icon-chevron-right  pr-2"
								></i>
								<span>Original photos complementing select stories.</span>
							</li>
							<li className="d-flex align-items-center pb-3">
								<i
									aria-hidden="true"
									className="icon icon-chevron-right pr-2"
								></i>
								<span>Global tales for a unique cultural insight.</span>
							</li>
							<li className="d-flex align-items-center pb-3">
								<i
									aria-hidden="true"
									className="icon icon-chevron-right pr-2"
								></i>
								<span>The perfect gift: a monthly dose of positivity.</span>
							</li>
						</ul>
					</div>

					<section className="elementor-section">
						<div className="row d-flex">
							{iconBoxes.map((box, index) => (
								<div key={index} className="col d-flex justify-content-center">
									<IconBox icon={box.icon} title={box.title} />
								</div>
							))}
						</div>
					</section>
				</div>
			</div>
		</div>
	);
};
