import { sendPasswordResetEmail } from "firebase/auth";

function ForgotPassword({
	handleAuthSubmitClick,
	authInfo,
	setIsLoading,
	isSignIn,
	setIsSignIn,
	setResponse,
	setError,
	setShowAuth,
	handleChange,
	auth,
}) {
	return (
		<>
			<div className="signin__form">
				<div className="signin__form__text">
					<div className="signin__form__text">
						<p>
							Please enter the email associated with your account to reset your
							password.
						</p>
					</div>
					<form
						onSubmit={(event) => {
							handleAuthSubmitClick(
								event,
								authInfo,
								setIsLoading,
								setIsSignIn,
								isSignIn,
								setResponse,
								setError,
								setShowAuth,
								auth
							);
						}}
					>
						<input
							type="email"
							placeholder="Email*"
							name="email"
							value={authInfo.email}
							onChange={handleChange}
							required
						/>

						<button type="submit" className="site-btn">
							Submit
						</button>
					</form>
					<button
						onClick={() =>
							setIsSignIn({
								...isSignIn,
								forgotPassword: false,
								passwordReset: false,
							})
						}
						className="site-btn mt-4"
					>
						Return to login
					</button>
				</div>
			</div>
		</>
	);
}

export default ForgotPassword;
