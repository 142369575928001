import { createContext, useState } from "react";
export const Loading = createContext();
export const AuthContext = createContext();
export const ModalContext = createContext();
export const ProductContext = createContext();
export const SubscriptionSelectedContext = createContext();
export const ScreenResizeContext = createContext();
export const SubscriptionSelectedProvider = (props) => {
	const [subscription, setSubscription] = useState(null);
  
	return (
	  <SubscriptionSelectedContext.Provider value={[subscription, setSubscription]}>
		{props.children}
	  </SubscriptionSelectedContext.Provider>
	);
  };