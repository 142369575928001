import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";

function HeaderMobile() {
	return (
		<div className={"top-nav"}>
			<input id="menu-toggle" type="checkbox" />
			<label class="menu-button-container" for="menu-toggle">
				<div class="menu-button"></div>
			</label>
			<ul class="menu">
				<Link>One</Link>
				<Link>Two</Link>
				<Link>Three</Link>
				<Link>Four</Link>
				<Link>Five</Link>
			</ul>
		</div>
	);
}

export default HeaderMobile;
