import { useState, useEffect, useContext } from "react";
import {
	createUserWithEmailAndPassword,
	signOut,
	sendPasswordResetEmail,
} from "firebase/auth";
import {
	db,
	authObject,
	analytics,
	firebaseUiConfig,
} from "../../logic/firebaseLogic/firebaseConfig";
import {
	AuthContext,
	ModalContext,
	SubscriptionSelectedContext,
} from "../../context/context";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import ForgotPassword from "./ForgotPassword";

import handleAuthSubmitClick from "./handleAuthSubmitClick";
import Modal from "../modal/Modal";
import Loader from "../Loader";
export const Auth = ({ display, authRef, setAuthButton, user }) => {
	const { showAuth, setShowAuth, auth } = useContext(AuthContext);
	const [subscription, setSubscription] = useContext(
		SubscriptionSelectedContext
	);
	const [isLoading, setIsLoading] = useState(false);
	const [isSignIn, setIsSignIn] = useState({
		text: "Sign In",
		userConfirmation: false,
		forgotPassword: false,
		passwordReset: false,
	});
	const {
		response,
		setResponse,
		delay,
		setDelay,
		customErrorDelay,
		setCustomErrorDelay,
		error,
		setError,
	} = useContext(ModalContext);
	function handleTabClick(event, text) {
		event.preventDefault();
		setIsSignIn({
			text,
			userConfirmation: false,
			forgotPassword: false,
			passwordReset: false,
		});
	}
	const [authInfo, setAuthInfo] = useState({
		email: "",
		password: "",
		name: "",
		code: "",
		new_password: "",
	});

	useEffect(() => {
		setAuthInfo({
			email: "",
			password: "",
			name: "",
			code: "",
			new_password: "",
		});
	}, [isSignIn]);
	function handleChange(event) {
		const value = event.target.value;
		const name = event.target.name;
		setAuthInfo({
			...authInfo,
			[name]: value,
		});
	}
	function handleCloseButton() {
		setSubscription(false);
		setShowAuth(false);
	}
	const socialSignIn = () => {};
	if (showAuth) {
		if (isSignIn.forgotPassword) {
			return (
				<div className="signin" style={{ display }}>
					<Loader loading={isLoading} />
					<Modal response={response} error={error} setError={setError} />
					<div className="signin__warp">
						<div className="signin__content" ref={authRef}></div>
						<div>
							<ForgotPassword
								handleAuthSubmitClick={handleAuthSubmitClick}
								authInfo={authInfo}
								setIsLoading={setIsLoading}
								isSignIn={isSignIn}
								setIsSignIn={setIsSignIn}
								setResponse={setResponse}
								setError={setError}
								setShowAuth={setShowAuth}
								handleChange={handleChange}
								auth={auth}
							/>
						</div>
					</div>
				</div>
			);
		} else {
			return (
				<div className="signin" style={{ display }}>
					<Loader loading={isLoading} />
					<Modal response={response} error={error} setError={setError} />
					<div className="signin__warp">
						<div className="signin__content" ref={authRef}></div>
						<div>
							<div className="signin__form">
								<ul className="nav nav-tabs" role="tablist">
									<li className="nav-item">
										<button
											className={`nav-link btn ${
												isSignIn.text === "Register"
													? "active bg-dark"
													: "bg-light"
											}`}
											onClick={(event) => handleTabClick(event, "Register")}
											data-toggle="tab"
											role="tab"
											aria-selected={
												isSignIn.text === "Register" ? "true" : "false"
											}
										>
											Register
										</button>
									</li>
									<li className="nav-item">
										<button
											className={`nav-link btn ${
												isSignIn.text === "Sign In"
													? "active bg-dark"
													: "bg-light"
											}`}
											onClick={(event) => handleTabClick(event, "Sign In")}
											data-toggle="tab"
											role="tab"
											aria-selected={
												isSignIn.text === "Sign In" ? "true" : "false"
											}
										>
											Sign in
										</button>
									</li>
								</ul>
								<div className="tab-content">
									<div className="tab-pane active" id="tabs-1" role="tabpanel">
										<div className="signin__form__text">
											<p>with your social network :</p>
											<div className="signin__form__signup__social">
												<button
													className="facebook"
													onClick={() => socialSignIn("facebook")}
												>
													<i className="fa fa-facebook"></i>
												</button>
												<button
													className="twitter"
													onClick={() => socialSignIn("twitter")}
												>
													<i className="fa fa-twitter"></i>
												</button>
												<button
													className="google"
													onClick={() => socialSignIn("google")}
												>
													<i className="fa fa-google"></i>
												</button>
											</div>
											<div className="divide">or</div>

											<form
												onSubmit={(event) =>
													handleAuthSubmitClick(
														event,
														authInfo,
														setIsLoading,
														setIsSignIn,
														isSignIn,
														setResponse,
														setError,
														setShowAuth,
														auth
													)
												}
											>
												<input
													type="text"
													placeholder="Email Address*"
													name="email"
													value={authInfo.email}
													onChange={handleChange}
													required
												/>
												<input
													type="password"
													placeholder="Password*"
													name="password"
													value={authInfo.password}
													onChange={handleChange}
													required
												/>
												{isSignIn.text === "Register" && (
													<>
														<label htmlFor="sign-agree-check">
															<input
																type="checkbox"
																id="sign-agree-check"
																name="termsAndConditions"
																checked={true}
																readOnly
																required
															/>
															<span className="checkmark"></span>By submitting
															this form, you agree to the{" "}
															<a
																href="https://docs.google.com/document/d/1ekIY4Hor9cT3sQnKGvP8kdr6kNJCUWl5p1gre2Fr2mM/edit?usp=share_link"
																target="_blank"
																rel="noreferrer"
															>
																terms & conditions
															</a>
														</label>
													</>
												)}
												<button type="submit" className="site-btn">
													{isSignIn.text}
												</button>
											</form>
											{isSignIn.text === "Sign In" && (
												<button
													className="mt-3 site-btn"
													onClick={() =>
														setIsSignIn({ ...isSignIn, forgotPassword: true })
													}
												>
													Forgot your password?
												</button>
											)}
										</div>
										<button
											className="site-btn close-btn"
											onClick={() => handleCloseButton()}
										>
											Close
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		}
	}
};
