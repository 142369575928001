import { HashLink as Link } from "react-router-hash-link";
const Board = () => {
	return (
		<main>
			<div className="hero-home bg-mockup hero-bottom-border">
				<div className="content">
					<h1 className="animated-element">Welcome to Uplift Crate!</h1>
					<p className="animated-element">
						Curated with care, designed to inspire - Get your monthly dose of
						positivity!
					</p>
					<Link to="/#mouse" className="btn-1 medium animated-element">
						Start Your Journey
					</Link>
					<div className="mouse-frame nice-scroll" id="mouse">
						<div className="mouse"></div>
					</div>
				</div>
			</div>
		</main>
	);
};

export default Board;
