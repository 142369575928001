import { useContext, useEffect, useState } from "react";
import { AuthContext, ScreenResizeContext } from "../context/context";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import HeaderMobile from "./headerMobile/HeaderMobile";

const Header = () => {
	const { setShowAuth, isUser } = useContext(AuthContext);
	const navigate = useNavigate();
	const [showMobileHeader, setShowMobileHeader] = useState(false);
	const { width } = useContext(ScreenResizeContext);
	useEffect(() => {
		width < 900 ? setShowMobileHeader(true) : setShowMobileHeader(false);
	}, [width]);
	const handleProfileClick = () => {
		if (isUser) {
			navigate("/profile");
		} else {
			setShowAuth(true);
		}
	};

	return (
		<header className="main-header sticky">
			<div className="container">
				<div className="row">
					{showMobileHeader && (
						<div className="col-6">
							<HeaderMobile />
						</div>
					)}

					<div className="col-lg-3 col-6 col-sm-6 logoDiv">
						<div id="logo" className="logoDiv">
							<h1 className="logoDiv">
								<Link to="/" title="Uplift Crate Home">
									Uplift Crate Home
								</Link>
							</h1>
						</div>
					</div>
					{!showMobileHeader && (
						<div className="col-lg-9 col-3 col-sm-9">
							<ul id="menuIcons">
								<li>
									<a href="#" onClick={handleProfileClick}>
										<i className="icon icon-user"></i>
									</a>
								</li>
							</ul>

							<nav id="menu" className="main-menu">
								<ul>
									<li>
										<span>
											<Link to="/" className="navLink">
												Home
											</Link>
										</span>
									</li>
									<li>
										<span>
											<Link to="/faq" className="navLink">
												Faq
											</Link>
										</span>
									</li>
									<li>
										<span>
											<Link to="/contacts" className="navLink">
												Contacts
											</Link>
										</span>
									</li>
								</ul>
							</nav>
						</div>
					)}
				</div>
			</div>
		</header>
	);
};

export default Header;
