function ContactsPage() {
	return (
		<div>
			<div id="page">
				<div className="sub-header">
					<div className="container">
						<h1>Our Contacts</h1>
					</div>
				</div>
				<main>
					<div id="map" className="map"></div>
					<div className="contacts">
						<div className="container">
							<div className="main-title">
								<span>
									<em></em>
								</span>
								<h2>Contact Details</h2>
								<p>Contact us</p>
							</div>
							<div className="row">
								<div className="col-lg-4 animated-element">
									<a
										href="https://goo.gl/maps/vKgGyZe2JSRLDnYH6"
										target="_blank"
									>
										<div className="box text-center">
											<div className="icon d-flex align-items-end">
												<i className="icon icon-map-marker"></i>
											</div>
											<h3 className="contact-title">Address</h3>
											<p>1234 Street Name, City Name, USA</p>
										</div>
									</a>
								</div>
								<div className="col-lg-4 animated-element">
									<a href="mailto:info@yourdomain.com">
										<div className="box text-center">
											<div className="icon d-flex align-items-end">
												<i className="icon icon-email"></i>
											</div>
											<h3 className="contact-title">Email</h3>
											<p>info@yourdomain.com</p>
										</div>
									</a>
								</div>
								<div className="col-lg-4 animated-element">
									<a href="tel:+3630123456789">
										<div className="box text-center">
											<div className="icon d-flex align-items-end">
												<i className="icon icon-phone-handset"></i>
											</div>
											<h3 className="contact-title">Phone</h3>
											<p>+3630123456789</p>
										</div>
									</a>
								</div>
							</div>
						</div>
					</div>
				</main>
			</div>
			<div id="toTop">
				<i className="icon icon-chevron-up"></i>
			</div>
		</div>
	);
}

export default ContactsPage;
