import HowItWorksDash from "../img/howItWorksDash.webp";
import HowItWorks1 from "../img/howItWorks2.webp";
import HowItWorks2 from "../img/howItWorks1.webp";
import HowItWorks3 from "../img/howItWorks3.webp";

const InfoColumn = ({ image, title, children }) => (
	<div className="col-sm-4 text-center">
		<figure>
			<img
				style={{ width: "50%" }}
				src={image}
				className="img-fluid rounded"
				alt=""
			/>
		</figure>
		<div className="py-1"></div>
		<h3 className="text-dark">{title}</h3>
		<div className="py-1"></div>
		<p className="mt-1">{children}</p>
	</div>
);
export const HowItWorks = () => (
	<div className="row mt-5 mb-4">
		<main id="main" className="col-md-12">
			<article id="post-869">
				<div className="entry-content main-home">
					<div className="row text-center">
						<div className="col-sm-12">
							<div className="py-3">
								<h2 className="section-title dark_title">
									Here’s <strong>How It Works</strong>
								</h2>
								<div className="section-subtitle dark_subtitle">
									New Boxes Released Monthly
								</div>
							</div>
						</div>
					</div>

					<div
						className="row"
						style={{
							backgroundImage: `url(${HowItWorksDash})`,
							backgroundRepeat: "no-repeat",
						}}
					>
						<InfoColumn image={HowItWorks1} title="Select Your Subscription">
							Start your journey of kindness. Each box brings positivity to your
							doorstep.
						</InfoColumn>
						<InfoColumn image={HowItWorks2} title="Receive Your Uplift Crate">
							Discover acts of kindness from cultures around the globe.
						</InfoColumn>
						<InfoColumn image={HowItWorks3} title="Share the Inspiration">
							Join a global community of positivity-seekers. Connect, share, and
							spread joy.
						</InfoColumn>
					</div>
				</div>
			</article>
		</main>
	</div>
);
