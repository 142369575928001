import { getFirestore } from "firebase/firestore";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFunctions} from "firebase/functions";

import { getAnalytics } from "firebase/analytics";
const STRIPE_PUBLISHABLE_KEY =
	"pk_test_51HCom0L1Fjbgpl3Jr9ZjUDGRJSFtw5IqiMN98iJSH0jyysdIHK1fknn2k1QKxs9uWhRY4P3DYuEWVHbJDJ8Cl41Q00gQOa4Syl";
const firebaseConfig = {
	apiKey: "AIzaSyDMt-HwbaRUrjRsC8EjFaU6mDbtC_0v5X0",
	authDomain: "uplift-crate.firebaseapp.com",
	projectId: "uplift-crate",
	storageBucket: "uplift-crate.appspot.com",
	messagingSenderId: "312933013076",
	appId: "1:312933013076:web:3c2fd27a847aca01b0bfc1",
	measurementId: "G-48R0E3Z14W",
};

// Initialize Firebase
const main = initializeApp(firebaseConfig);
// Initialize FireStore
const db = getFirestore(main);
// Initialize Auth
const authObject = getAuth(main);
const functions = getFunctions(main);
const analytics = getAnalytics(main);

export { db, authObject, analytics, STRIPE_PUBLISHABLE_KEY, functions };
