import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { ModalContext } from "../../context/context";
import styles from "./modal.module.css";
function Modal() {
	const [isModal, setIsModal] = useState(false);

	const {
		response,
		setResponse,
		delay,
		setDelay,
		customErrorDelay,
		setCustomErrorDelay,
		error,
		setError,
	} = useContext(ModalContext);
	useEffect(() => {
		if (response) {
			setIsModal(true);
			const timer = setTimeout(() => {
				setIsModal(false);
				setResponse && setResponse(null);
			}, delay);
			return () => clearTimeout(timer);
		}
		if (error) {
			setIsModal(true);
			const timer = setTimeout(() => {
				setIsModal(false);
				setError(null);
			}, delay + customErrorDelay);
			return () => clearTimeout(timer);
		}
		return;
	}, [response, error]);
	function handleCloseModalClick() {
		if (error) {
			setError(null);
		}
		if (response) {
			setResponse && setResponse(null);
		}
		setIsModal(false);
	}
	if (isModal) {
		return (
			<main className={styles.modal}>
				<div>
					{response && (
						<p data-testid="responseParagraph" id="responseSuccess">
							Success
						</p>
					)}
					{error && (
						<div data-testid="errorDiv">
							<p data-testid="errorMessage">{error.message}</p>
							{error.code && <p data-testid="errorCode">{error.code}</p>}
						</div>
					)}
					{response || error.code || error.code || (
						<div data-testid="errorDiv">
							<p data-testid="errorMessage">{error}</p>
						</div>
					)}
				</div>
				<button onClick={handleCloseModalClick}>Close</button>
			</main>
		);
	}
}

export default Modal;
