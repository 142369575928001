import { useContext } from "react";
import { Loading } from "../context/context";

function Loader() {
	const { loading } = useContext(Loading);
	if(loading) {
	return (
		<div id="preloader">
			<div data-loader="circle-side"></div>
		</div>
	);}
}

export default Loader;