import React, { useContext } from "react";
import { SubscriptionSelectedContext, AuthContext } from "../context/context";
import { useNavigate } from "react-router-dom";

const SubscriptionCard = ({
	subscriptionType,
	price,
	imgSrc,
	link,
	text,
	nameData,
	buttonText,
	priceSecond
}) => {
	const navigate = useNavigate();
	const [_, setSubscription] = useContext(SubscriptionSelectedContext);
	function handleSelectClick() {
		setSubscription(nameData);
		navigate("checkout");
	}
	return (
		<div className="col-sm-4" style={{ paddingBottom: "50px" }}>
			<div className="themeslr-pricing-table">
				<div className="pricing pricing--pema">
					<div className="pricing__item pricing__item--nofeatured">
						<div className="pricing__title">
							<span>{subscriptionType}</span>
						</div>
						<div className="pricing__period">
							<svg
								width="87"
								height="98"
								viewBox="0 0 87 98"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M58.4408 -32.4016L96.1635 -25.8422L122.747 1.47392L128.037 39.1131L110.012 72.6984L75.5591 89.4014L37.8364 82.8421L11.2532 55.5259L5.96338 17.8867L23.9874 -15.6986L58.4408 -32.4016Z"
									fill="#F9DBD9"
								/>
							</svg>
							<div className="pricing__bubble_content">
								<strong>{price}</strong>
								<br />
								{priceSecond}
							</div>
						</div>
						<p className="pricing__sentence"></p>
						<div className="themeslr-pricing-image--holder text-center">
							<img src={imgSrc} alt={subscriptionType} />
						</div>
						<ul className="pricing__feature-list">
							{text.map((item) => (
								<li className="pricing__feature" key={item}>
									{item}
								</li>
							))}
						</ul>
						<div className="themeslr-pricing-button--holder themeslr_button_shortcode text-center">
							<a
								className="btn btn-primary"
								style={{
									backgroundColor: "#f25c04",
									border: "1px black solid",
								}}
								onClick={() => handleSelectClick()}
							>
								Select Envelope
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const PricingRow = ({ data }) => {
	return (
		<div className="row justify-content-around">
			{data.map((item, index) => (
				<SubscriptionCard
					key={index}
					subscriptionType={item.subscriptionType}
					price={item.price}
					imgSrc={item.imgSrc}
					text={item.text}
					nameData={item.nameData}
					priceSecond={item.priceSecond}
				/>
			))}
		</div>
	);
};

export default PricingRow;
