import "./css/icomoon/css/iconfont.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./css/style.css";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import debounce from "./logic/debounceFunction";

import { React, useEffect, useState } from "react";
import {
	getDocs,
	collection,
	addDoc,
	doc,
	deleteDoc,
} from "firebase/firestore";

import {
	db,
	authObject,
	analytics,
} from "./logic/firebaseLogic/firebaseConfig";
import {
	Loading,
	AuthContext,
	ModalContext,
	ProductContext,
	SubscriptionSelectedProvider,
	ScreenResizeContext,
} from "./context/context.js";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import HomePage from "./pages/HomePage.jsx";
import AppRouter from "./AppRouter ";
function App() {
	const [loading, setLoading] = useState(true);
	const auth = getAuth();
	const [isUser, setIsUser] = useState(false);
	const [showAuth, setShowAuth] = useState(false);
	const [response, setResponse] = useState(false);
	const [delay, setDelay] = useState(3000);
	const [customErrorDelay, setCustomErrorDelay] = useState(5000);
	const [error, setError] = useState(false);
	const [productArray, setProductArray] = useState(false);
	const [width, setWidth] = useState(window.innerWidth);

	useEffect(() => {
		window.alert("The website is under construction, no orders will be filled")
		window.addEventListener(
			"resize",
			debounce(() => setWidth(window.innerWidth), 250, false),
			false
		);
		onAuthStateChanged(auth, (user) => {
			if (user) {
				// User is signed in, see docs for a list of available properties
				// https://firebase.google.com/docs/reference/js/firebase.User
				const uid = user;
				setIsUser(uid);
				setShowAuth(false);
				// ...
			} else {
				// User is signed out
				setIsUser(false);
			}
		});
	}, []);
	const handleGetProducts = async () => {
		try {
			const dataToGet = collection(db, "products");
			const productSnapshot = await getDocs(dataToGet);

			// Filter out the inactive products
			const activeProducts = productSnapshot.docs.filter(
				(doc) => doc.data().active
			);

			const productPromises = activeProducts.map(async (productDoc) => {
				const productData = productDoc.data();
				const productId = productDoc.id;

				const pricesRef = collection(db, "products", productId, "prices");
				const pricesSnapshot = await getDocs(pricesRef);
				const pricesData = pricesSnapshot.docs.map((priceDoc) => {
					const priceData = priceDoc.data();
					const priceId = priceDoc.id;
					return {
						...priceData,
						priceId,
					};
				});

				return {
					...productData,
					productId,
					prices: pricesData,
				};
			});

			const dataSeparated = await Promise.all(productPromises);
			setProductArray(dataSeparated);
		} catch (error) {
			setError(error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		handleGetProducts();
	}, []);
	return (
		<ScreenResizeContext.Provider value={{ width }}>
			<SubscriptionSelectedProvider>
				<ProductContext.Provider value={{ productArray }}>
					<ModalContext.Provider
						value={{
							response,
							setResponse,
							delay,
							setDelay,
							customErrorDelay,
							setCustomErrorDelay,
							error,
							setError,
						}}
					>
						<AuthContext.Provider
							value={{ isUser, setIsUser, auth, showAuth, setShowAuth }}
						>
							<Loading.Provider value={{ loading, setLoading }}>
								<AppRouter />
							</Loading.Provider>
						</AuthContext.Provider>
					</ModalContext.Provider>
				</ProductContext.Provider>
			</SubscriptionSelectedProvider>
		</ScreenResizeContext.Provider>
	);
}

export default App;
