import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

import {
	AuthContext,
	SubscriptionSelectedContext,
	ProductContext,
	Loading,
	ModalContext,
} from "../context/context";
import {
	addDoc,
	onSnapshot,
	collection,
	getDoc,
	doc,
	getDocs,
	updateDoc,
	serverTimestamp,
} from "firebase/firestore";
import {
	STRIPE_PUBLISHABLE_KEY,
	db,
} from "../logic/firebaseLogic/firebaseConfig";
import { loadStripe } from "@stripe/stripe-js";
export const ShippingForm = ({ checkout }) => {
	const { showAuth, setShowAuth, auth, isUser } = useContext(AuthContext);
	const [subscription, setSubscription] = useContext(
		SubscriptionSelectedContext
	);
	const { setError, setResponse } = useContext(ModalContext);
	const { setLoading } = useContext(Loading);
	const { productArray } = useContext(ProductContext);
	const [productId, setProductId] = useState(null);
	const navigate = useNavigate();
	const [formData, setFormData] = useState({
		firstName: "",
		lastName: "",
		address: "",
		address2: "",
		country: "us",
		state: "",
		zip: "",
		docId: "",
		createdAt: "",
		updatedAt: "",
	});

	const getShippingAddress = async (userId) => {
		const shippingCollectionRef = collection(db, "users", userId, "shipping");
		const snapshot = await getDocs(shippingCollectionRef);
		if (snapshot.empty) {
			console.log("No matching documents.");
			return;
		}
		snapshot.docs.forEach((doc) => {
			const shippingData = doc.data();
			setFormData({
				firstName: shippingData.firstName,
				lastName: shippingData.lastName,
				address: shippingData.address,
				address2: shippingData.address2,
				country: shippingData.country,
				state: shippingData.state,
				zip: shippingData.zip,
				createdAt: shippingData.createdAt,
				docId: doc.id,
			});
		});
	};
	useEffect(() => {
		if (auth && productArray && subscription) {
			const [type, duration] = subscription;
			const foundProduct = productArray.find(
				(product) =>
					product.metadata.type === type &&
					product.metadata.duration === String(duration)
			);
			if (foundProduct) {
				setProductId(foundProduct.prices[0].priceId);
				getShippingAddress(isUser.uid);
			} else {
				setError("An error occurred, please reach out to us!");
				setTimeout(() => {
					navigate("/");
				}, 2000);
			}
		} else {
			if (auth) {
				try {
					getShippingAddress(isUser.uid);
				} catch (error) {
					console.log(error);
					setError(error);
				}
			} else {
				navigate("/");
			}
		}
	}, [auth, subscription, productArray]);

	const handleSubscribe = async (e) => {
		e.preventDefault();
		setLoading(true);
		const currentUser = isUser.uid;
		const timeStamp = serverTimestamp();
		let response;
		try {
			if (formData.docId) {
				const shippingDocRef = doc(
					db,
					"users",
					currentUser,
					"shipping",
					formData.docId
				);

				await updateDoc(shippingDocRef, {
					firstName: formData.firstName,
					lastName: formData.lastName,
					country: formData.country,
					state: formData.state,
					zip: formData.zip,
					address: formData.address,
					address2: formData.address2,
					createdAt: formData.createdAt,
					updatedAt: timeStamp,
				});
			} else {
				await addDoc(collection(db, "users", currentUser, "shipping"), {
					firstName: formData.firstName,
					lastName: formData.lastName,
					country: formData.country,
					state: formData.state,
					zip: formData.zip,
					address: formData.address,
					address2: formData.address2,
					createdAt: timeStamp,
				});
			}
			if (!checkout) {
				setResponse("Succesfully updated shipping address");
				setLoading(false);
			} else {
				handleCheckout();
			}
		} catch (error) {
			console.error("Error in handleSubscribe: ", error);
			setError(error);
		}
	};
	const handleCheckout = async () => {
		setLoading(true);
		const currentUser = isUser.uid;
		const docRef = await addDoc(
			collection(db, "users", currentUser, "checkout_sessions"),
			{
				price: productId,
				allow_promotion_codes: true,
				success_url: `${window.location.origin}/thankyou`,
				cancel_url: window.location.origin,
				shipping_address_collection: {
					allowed_countries: ["US"],
				},
			}
		);
		const unsubscribe = onSnapshot(docRef, async (snap) => {
			const { sessionId } = snap.data();
			if (sessionId) {
				const stripe = await loadStripe(STRIPE_PUBLISHABLE_KEY);
				stripe.redirectToCheckout({ sessionId });
				setLoading(false);
			}
		});
		return unsubscribe;
	};
	const [errors, setErrors] = useState({});

	const handleChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
		if (e.target.value === "") {
			setErrors({ ...errors, [e.target.name]: "This field is required." });
		} else {
			setErrors({ ...errors, [e.target.name]: null });
		}
	};

	return (
		<div className="container mt-5 checkout">
			<div className="row">
				<div className="col">
					<h4>Update Shipping Address</h4>
					<p className="mt-2">
						Your shipping information will be stored securely to ensure a smooth
						and convenient shopping experience.
					</p>
					<p>
						For your peace of mind, we have partnered with Stripe, a global
						leader in online payment processing.
					</p>

					<form
						onSubmit={(e) => handleSubscribe(e)}
						className="needs-validation"
						noValidate=""
					>
						<div className="row">
							<div className="col-md-6 mb-3">
								<label htmlFor="firstName">First name</label>
								<input
									type="text"
									className="form-control"
									id="firstName"
									name="firstName"
									placeholder=""
									value={formData.firstName}
									onChange={handleChange}
									required
								/>
								{errors.firstName && (
									<div className="invalid-feedback">{errors.firstName}</div>
								)}
							</div>
							<div className="col-md-6 mb-3">
								<label htmlFor="lastName">Last name</label>
								<input
									type="text"
									className="form-control"
									id="lastName"
									name="lastName"
									placeholder=""
									value={formData.lastName}
									onChange={handleChange}
									required
								/>
								{errors.lastName && (
									<div className="invalid-feedback">{errors.lastName}</div>
								)}
							</div>
						</div>
						<div className="mb-3 ml-3 mr-3">
							<label for="address">Address</label>
							<input
								type="text"
								className="form-control"
								onChange={handleChange}
								id="address"
								name="address"
								value={formData.address}
								placeholder="1234 Main St"
								required
							/>
							<div className="invalid-feedback">
								Please enter your shipping address.
							</div>
						</div>
						<div className="mb-3 ml-3 mr-3">
							<label for="address2">
								Address 2 <span className="text-muted">(Optional)</span>
							</label>
							<input
								type="text"
								className="form-control"
								onChange={handleChange}
								name="address2"
								id="address2"
								value={formData.address2}
								placeholder="Apartment or suite"
							/>
						</div>
						<div className="row">
							<div className="col-md-5 mb-3">
								<label for="country">Country</label>
								<select
									className="custom-select d-block w-100"
									id="country"
									name="country"
									required
									value={formData.country}
									onChange={handleChange}
								>
									<option value="us">United States</option>
								</select>
							</div>
							<div className="col-md-4 mb-3">
								<label for="state">State</label>
								<select
									className="custom-select d-block w-100"
									id="state"
									name="state"
									value={formData.state}
									onChange={handleChange}
									required
								>
									<option value="">Choose...</option>
									<option value="Alabama">Alabama</option>
									<option value="Alaska">Alaska</option>
									<option value="Arizona">Arizona</option>
									<option value="Arkansas">Arkansas</option>
									<option value="California">California</option>
									<option value="Colorado">Colorado</option>
									<option value="Connecticut">Connecticut</option>
									<option value="Delaware">Delaware</option>
									<option value="Florida">Florida</option>
									<option value="Georgia">Georgia</option>
									<option value="Hawaii">Hawaii</option>
									<option value="Idaho">Idaho</option>
									<option value="Illinois">Illinois</option>
									<option value="Indiana">Indiana</option>
									<option value="Iowa">Iowa</option>
									<option value="Kansas">Kansas</option>
									<option value="Kentucky">Kentucky</option>
									<option value="Louisiana">Louisiana</option>
									<option value="Maine">Maine</option>
									<option value="Maryland">Maryland</option>
									<option value="Massachusetts">Massachusetts</option>
									<option value="Michigan">Michigan</option>
									<option value="Minnesota">Minnesota</option>
									<option value="Mississippi">Mississippi</option>
									<option value="Missouri">Missouri</option>
									<option value="Montana">Montana</option>
									<option value="Nebraska">Nebraska</option>
									<option value="Nevada">Nevada</option>
									<option value="New Hampshire">New Hampshire</option>
									<option value="New Jersey">New Jersey</option>
									<option value="New Mexico">New Mexico</option>
									<option value="New York">New York</option>
									<option value="North Carolina">North Carolina</option>
									<option value="North Dakota">North Dakota</option>
									<option value="Ohio">Ohio</option>
									<option value="Oklahoma">Oklahoma</option>
									<option value="Oregon">Oregon</option>
									<option value="Pennsylvania">Pennsylvania</option>
									<option value="Rhode Island">Rhode Island</option>
									<option value="South Carolina">South Carolina</option>
									<option value="South Dakota">South Dakota</option>
									<option value="Tennessee">Tennessee</option>
									<option value="Texas">Texas</option>
									<option value="Utah">Utah</option>
									<option value="Vermont">Vermont</option>
									<option value="Virginia">Virginia</option>
									<option value="Washington">Washington</option>
									<option value="West Virginia">West Virginia</option>
									<option value="Wisconsin">Wisconsin</option>
									<option value="Wyoming">Wyoming</option>
								</select>

								<div className="invalid-feedback">
									Please provide a valid state.
								</div>
							</div>
							<div className="col-md-3 mb-3">
								<label for="zip">Zip</label>
								<input
									type="text"
									className="form-control"
									id="zip"
									name="zip"
									placeholder="Five digit zip code"
									pattern="\d{5}"
									onChange={handleChange}
									value={formData.zip}
									required
								/>
							</div>
						</div>
						<p>
							Remember, Uplift Crate empowers you with full control over your
							account. You can update your shipping address, payment details, or
							even change your subscription plan anytime via your profile page.
							We are here to uplift your experience with us!
						</p>

						<button className="btn btn-block cta-button mt-4" type="submit">
							{checkout
								? "Continue to secure checkout"
								: "Update Shipping Information"}
						</button>

						<button
							className="btn btn-secondary btn-block ml-0 mt-4 d-block" style={{marginLeft: 0}}
							onClick={() => navigate("/")}
						>
							Back To Home Page
						</button>
					</form>
				</div>
			</div>
		</div>
	);
};
