import React, { useContext } from "react";
import {
	createUserWithEmailAndPassword,
	signOut,
	signInWithEmailAndPassword,
	sendPasswordResetEmail,
} from "firebase/auth";
import { authObject } from "../../logic/firebaseLogic/firebaseConfig";
import { getAuth } from "firebase/auth";

async function handleAuthSubmitClick(
	event,
	authInfo,
	setIsLoading,
	setIsSignIn,
	isSignIn,
	setResponse,
	setError,
	setShowAuth,
	auth
) {
	event.preventDefault();
	const { email, password, name, code, new_password } = authInfo;
	setIsLoading(true);
	if (isSignIn.userConfirmation) {
		try {
			const response = await auth.confirmSignUp(email, code);
			setResponse(response);
			setIsSignIn({
				text: "Sign In",
				userConfirmation: false,
				forgotPassword: false,
				passwordReset: false,
			});
			setShowAuth("none");
		} catch (error) {
			setError({message:"Error confirming sign-up", code: error.message});
		} finally {
			setIsLoading(false);
		}
	} else if (isSignIn.forgotPassword) {
		try {
			const response = sendPasswordResetEmail(auth, email)
			setResponse(response)
			.then (() => {
				setIsSignIn({
					text: "Sign In",
					userConfirmation: false,
					forgotPassword: false,
					passwordReset: false,
				});
			})
		} catch (error) {
			setError({message:"Error resetting password", code: error.message});
		} finally {
			setIsLoading(false);
		}
	} else {
		if (isSignIn.text === "Register") {
			try {
				const signUpData = await createUserWithEmailAndPassword(
					auth,
					email,
					password
				);
				setResponse("Successfully created account!");
			} catch (error) {
				setError({message:"Error signing up:", code: error.message});
			} finally {
				setIsLoading(false);
			}
		} else {
			try {
				const userData = await signInWithEmailAndPassword(
					auth,
					email,
					password
				);
				setResponse("Sign in success");
			} catch (error) {
				setError({message:"Error signing in:", code: error.message});
			} finally {
				setIsLoading(false);
			}
		}
	}
}
export default handleAuthSubmitClick;
