import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import HomePage from "./pages/HomePage";
import ContactsPage from "./pages/ContactsPage";
import Faq from "./pages/Faq";
import Loader from "./components/Loader";
import Header from "./components/Header.jsx";
import Board from "./components/Board";
import Footer from "./components/Footer";
import Modal from "./components/modal/Modal";
import { AuthContext } from "./context/context";
import {Auth}  from "./components/auth/Auth";
import { Profile } from "./pages/Profile";
import { AuthenticateCheckout } from "./components/AuthenticateCheckout";
import { ThankYou } from "./pages/ThankYou";
import { ShippingForm } from "./components/ShippingForm";
// import AuthModule from "./components/auth/AuthModule";
import PageNotFound from "./pages/PageNotFound"
function AppRouter() {
	return (
		<Router>
			<Loader />
			<Header />
			<Board />
			<Auth />
			<Modal />
			<Routes>
				<Route exact path="/" element={<HomePage />} />
				<Route path="contact" element={<ContactsPage />} />
				<Route path="faq" element={<Faq />} />
				<Route path="profile" element={<Profile/>}/>
				<Route path="checkout" element={<AuthenticateCheckout/>} />
				<Route path="thankyou" element={<ThankYou/>} />
				<Route path="shipping" element={<ShippingForm/>} />
				<Route path="*" element={<PageNotFound />} />
			</Routes>
			<Footer />
		</Router>
	);
}

export default AppRouter;
