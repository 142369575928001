import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const ThankYou = () => {
	const navigate = useNavigate()
	useEffect(() => {
		const element = document.getElementById('thankYouBlock');
		if (element) {
		  element.scrollIntoView({ behavior: 'smooth' });
		}
	  }, []);
	return (
		<body>
			<div className="vh-100 d-flex justify-content-center align-items-center" id="thankYouBlock">
				<div>
					<div className="mb-4 text-center">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							className="text-success bi bi-check-circle-fill"
							width="75"
							height="75"
							fill="#f25c04"
							viewBox="0 0 16 16"
						>
							<path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
						</svg>
					</div>
					<div className="text-center" >
						<h1>Thank You !</h1>
						<p id="thankYouMessage">
							A confirmation email has been
							dispatched to your registered email address. Rest assured, your
							Uplift package will be shipped promptly.
						</p>

						<button className="btn cta-button" onClick={() => navigate("/")}>Back Home</button>
						<button className="btn cta-button" onClick={() => navigate("/profile")}>My Profile</button>

					</div>
				</div>
			</div>
		</body>
	);
};
