import { HowItWorks } from "../components/HowItWorks";
import { WhatsInside } from "../components/WhatsInside";
import PricingRow from "../components/PricingRow";
import pricingImage from "../img/pricing-image-1.webp";
function HomePage() {
	return (
		<div>
			<div id="page">
				<div>
					<HowItWorks />
					<WhatsInside />
					<PricingRow
						data={[
							{
								subscriptionType: "Monthly",
								price: "$19",
								priceSecond: "/month",
								imgSrc: pricingImage,
								text: [
									"Rebills every month",
									"Free Shipping",
									"Cancel Anytime",
								],
								nameData: ["envelope", 1],
							},
							{
								subscriptionType: "3-Month",
								price: "$53",
								priceSecond: "/3 month",
								imgSrc: pricingImage,
								text: [
									"Rebills every 3 months",
									"Free Shipping",
									"Cancel Anytime",
								],
								nameData: ["envelope", 3],
							},
							{
								subscriptionType: "6-Month",
								price: "$99",
								priceSecond: "/6 month",
								imgSrc: pricingImage,
								text: [
									"Rebills every 6 months",
									"Free Shipping",
									"Cancel Anytime",
								],
								nameData: ["envelope", 6],
							},
						]}
					/>

					<div className="banner animated-element">
						<div className="container">
							<div className="content">
								<div className="mask">
									<div className="textbox">
										<small>Your Happiness Journey</small>
										<h2>Uplift Crate</h2>
										<p>
											Inspiring stories and uplifting messages delivered to your
											doorstep.
										</p>
										<a href="testimonial.html" className="btn-1">
											See what others say
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div id="toTop">
				<i className="icon icon-chevron-up"></i>
			</div>
		</div>
	);
}

export default HomePage;
